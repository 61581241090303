
import { defineComponent } from 'vue';
import AuthService from '@/services/AuthService';
import PlayerService from '@/services/PlayerService';
import Player from '@/models/player.model';
import CurrentGame from '@/components/CurrentGame.vue';

export default defineComponent({
  name: 'Account',
  components: {
    CurrentGame
  },
  data () {
    return {
      authService: new AuthService(),
      playerService: new PlayerService(),
      player: {} as Player,
      loading: true
    }
  },
  mounted () {
    if (this.$route) {
      this.authService.SaveAuthToken(this.$route.hash);
      history.replaceState(null, '', '/account');
      this.$emit('userLoggedIn');
    }

    if (!this.authService.IsLoggedIn()) {
      window.open(process.env.VUE_APP_AUTH, '_self');
    } else {
      this.playerService.GetPlayerByLoginId(this.authService.CurrentLoginId() || '')
        .then((result) => { this.player = new Player(result) })
        .finally(() => { this.loading = false });
    }
  }
})
